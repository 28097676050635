import m from "mithril";
import BaseComponent from '../../BaseComponent';
import Messages from "./view/Messages";
import {ComponentContext} from "../../IComponent";
import MessageInput from "./view/MessageInput";
import ChatbotApi from "./model/ChatbotApi";
import ChatbotState from "./model/ChatbotState";
import ImageDetail from "./view/ImageDetail";

export default class Chatbot extends BaseComponent {

    public readonly api: ChatbotApi;
    public readonly state: ChatbotState;

    private readonly messages: Messages;
    private readonly messageInput: MessageInput;
    private readonly imageDetail: ImageDetail;

    constructor(
        $component: JQuery<HTMLElement>,
        context?: ComponentContext,
    ) {
        super($component, context);

        this.messages = new Messages(this);
        this.messageInput = new MessageInput(this);
        this.imageDetail = new ImageDetail(this);

        this.state = new ChatbotState();
        this.state.avatarPath = this.$component.data('avatar');
        this.state.nick = this.$component.data('nick');

        this.api = new ChatbotApi({
            conversation: this.$component.data('url-get-conversation'),
            send: this.$component.data('url-send'),
            stream: this.$component.data('url-stream'),
            download: this.$component.data('url-download'),
        });
    }

    public async init() {
        const conversation = await this.api.getConversation();
        this.state.messages = conversation.messages ?? [];
        this.state.locked = conversation.locked ?? false;

        m.mount(this.$component[0], {
            view: () => [
                m(this.messages),
                m(this.messageInput),
                m(this.imageDetail),
            ],
        });

        this.state.started = Date.now();

        const lastMessage = this.state.messages[this.state.messages.length - 1]
        if (!this.state.locked && lastMessage?.role === 'user' && lastMessage.content) {
            this.state.animateFirstMessageText = true;
            this.state.input = lastMessage.content;
            this.state.messages.pop();
            await this.send();
            this.state.animateFirstMessageText = false;
        }

    }


    public async send(): Promise<void> {
        if (this.state.locked) {
            return;
        }

        this.state.sending = true;
        try {
            this.state.messages.push({
                role: 'user',
                content: this.state.input,
                completed: true,
                created: Date.now(),
            });

            this.state.messages.push({
                role: 'assistant',
                content: '...',
                completed: false,
                created: Date.now(),
            });
            try {
                await this.api.sendMessage(this.state.input, message => {
                    this.state.messages[this.state.messages.length - 1].content = message.content;
                    m.redraw();
                });
            } catch (err) {
                console.error(err);
                this.state.locked = true;
                alert(this.translator.translate('server_error_message'));
            }

            this.state.input = '';
            m.redraw();
            this.state.messages = (await this.api.getConversation()).messages;
            m.redraw();
        } catch (err) {
            console.error(err);
        } finally {
            this.state.sending = false;
            m.redraw.sync();
            this.$component.find('textarea').focus();
        }
        m.redraw();
    }

}
