import IExtension from "./../../IExtension";
import {Naja} from "naja";

declare global {
    interface JQuery {
        sortable: any,
        selectpicker: any,
    }
}

export default class DatagridExtension implements IExtension {

    private static datagrid_autosubmit_timer;
    private static happy;

    private static datagridSerializeUrl(obj, prefix?: string) {
        var str = [];
        for(var p in obj) {
            if (obj.hasOwnProperty(p)) {
                var k = prefix ? prefix + "[" + p + "]" : p, v = obj[p];
                if (v !== null && v !== "") {
                    if (typeof v == "object") {
                        var r = DatagridExtension.datagridSerializeUrl(v, k);
                        if (r) {
                            str.push(r);
                        }
                    } else {
                        str.push(encodeURIComponent(k) + "=" + encodeURIComponent(v));
                    }
                }
            }
        }
        return str.join("&");
    }

    private naja: Naja;

    public initialize(naja: Naja) {
        this.naja = naja;
        naja.addEventListener('init', () => this.init($('body')));
        naja.snippetHandler.addEventListener('afterUpdate', e => this.init($(e.detail.snippet)));
        naja.addEventListener('before', (e) => this.onBefore($(<HTMLElement>e.target)));
        naja.addEventListener('success', (e) => this.onSuccess(e.detail.payload));
    }

    private init($root): void {
        const naja = this.naja;

        $root.on('click', '.datagrid a[data-open]', (e) => {
            const $target = $(e.currentTarget);
            naja.uiHandler.clickElement($target[0]);
            $('#' + $target.data('open')).modal('show');
        });

        $root.on('click', '.datagrid a[data-close]', e => {
            $(e.currentTarget).closest('form')[0].reset();
        });

        $root.on('change', '.datagrid input[name*="grid_group_action_item"]', function() {
            var $grid = $(this).closest('.datagrid');
            var $groupActions = $grid.find('[data-group-actions]');
            var $groupActionsSelect = $groupActions.find('select');
            let $secondInput = $grid.find('[data-input-required]');
            var $groupActionsSubmit = $groupActions.find('[type=submit]').show().attr('disabled', 1);
            var $inputs = $grid.find('input[name*="grid_group_action_item"]');

            if ($inputs.is(':checked')) {
                $groupActionsSelect.removeAttr('disabled');
                $groupActions.show();
            } else {
                $groupActionsSelect.attr('disabled', 1);
                $groupActions.hide();
            }

            if ($secondInput.length !== 0) {
                $secondInput.on('keyup', function () {
                    if (this.value) {
                        $groupActionsSubmit.removeAttr('disabled');
                    } else {
                        $groupActionsSubmit.attr('disabled', 1);
                    }
                }).keyup();
            }

            $groupActionsSelect.on('change', function() {
                if (this.value === '') {
                    $groupActionsSubmit.attr('disabled', 1);
                } else {
                    $groupActionsSubmit.removeAttr('disabled');
                }

                let comparisonValue = 'group_action[' + this.value + ']';
                if ($secondInput.attr('name') === comparisonValue) {
                    $secondInput.val('');
                    $groupActionsSubmit.attr('disabled', 1);
                }
            });
        }).change();

        $(document).on('click', '[data-datagrid-confirm]:not(.ajax)', function(e) {
            if (!confirm($(e.target).closest('a').attr('data-datagrid-confirm'))) {
                e.stopPropagation();
                return e.preventDefault();
            }
        });

        $(document).on('change', 'select[data-autosubmit-per-page]', function() {
            let button;
            button = $(this).parent().find('input[type=submit]');
            if (button.length === 0) {
                button = $(this).parent().find('button[type=submit]');
            }
            return naja.uiHandler.clickElement(button[0]);
        }).on('change', 'select[data-autosubmit]', function() {
            naja.uiHandler.submitForm($(this).closest('form').first()[0]);
        }).on('change', 'input[data-autosubmit][data-autosubmit-change]', function(e) {
            let $this, code;
            code = e.which || e.keyCode || 0;
            clearTimeout(DatagridExtension.datagrid_autosubmit_timer);
            $this = $(this);
            return DatagridExtension.datagrid_autosubmit_timer = setTimeout((function(_this) {
                return () => {
                    naja.uiHandler.submitForm( $this.closest('form').first()[0]);
                };
            })(this), 200);
        }).on('keyup', 'input[data-autosubmit], textarea[data-autosubmit]', function(e) {
            let $this, code;
            code = e.which || e.keyCode || 0;
            if ((code !== 13) && ((code >= 9 && code <= 40) || (code >= 112 && code <= 123))) {
                return;
            }
            clearTimeout(DatagridExtension.datagrid_autosubmit_timer);
            $this = $(this);
            return DatagridExtension.datagrid_autosubmit_timer = setTimeout((function(_this) {
                return () => {
                    naja.uiHandler.submitForm($this.closest('form').first()[0]);
                };
            })(this), 200);
        }).on('keydown', '.datagrid-inline-edit input', function(e) {
            let code;
            code = e.which || e.keyCode || 0;
            if (code === 13) {
                e.stopPropagation();
                e.preventDefault();
                return naja.uiHandler.clickElement($(this).closest('tr').find('.col-action-inline-edit [name="inline_edit[submit]"]')[0]);
            }
        });

        $(document).on('keydown', 'input[data-datagrid-manualsubmit]', function(e) {
            var code;
            code = e.which || e.keyCode || 0;
            if (code === 13) {
                e.stopPropagation();
                e.preventDefault();
                return naja.uiHandler.submitForm($(this).closest('form').first()[0]);
            }
        });

        this.datagridShiftGroupSelection();

        document.addEventListener('change', function(e) {
            let checked_inputs, counter, event, grid, i, ie, input, inputs, len, results, select, total;
            grid = (<HTMLElement>e.target).getAttribute('data-check');
            if (grid) {
                checked_inputs = document.querySelectorAll('input[data-check-all-' + grid + ']:checked');
                select = document.querySelector('.datagrid-' + grid + ' select[name="group_action[group_action]"]');
                if (select) {
                    counter = document.querySelector('.datagrid-' + grid + ' .datagrid-selected-rows-count');
                    if (checked_inputs.length) {
                        select.disabled = false;
                        total = document.querySelectorAll('input[data-check-all-' + grid + ']').length;
                        if (counter) {
                            counter.innerHTML = checked_inputs.length + '/' + total;
                        }
                    } else {
                        select.disabled = true;
                        select.value = "";
                        if (counter) {
                            counter.innerHTML = "";
                        }
                    }
                }
                ie = window.navigator.userAgent.indexOf("MSIE ");
                if (ie) {
                    event = document.createEvent('Event');
                    event.initEvent('change', true, true);
                } else {
                    event = new Event('change', {
                        'bubbles': true
                    });
                }
                if (select) {
                    select.dispatchEvent(event);
                }
            }
            grid = (<HTMLElement>e.target).getAttribute('data-check-all');
            if (grid) {
                inputs = document.querySelectorAll('input[type=checkbox][data-check-all-' + grid + ']');
                results = [];
                for (i = 0, len = inputs.length; i < len; i++) {
                    input = inputs[i];
                    input.checked = (<HTMLInputElement>e.target).checked;
                    ie = window.navigator.userAgent.indexOf("MSIE ");
                    if (ie) {
                        event = document.createEvent('Event');
                        event.initEvent('change', true, true);
                    } else {
                        event = new Event('change', {
                            'bubbles': true
                        });
                    }
                    results.push(input.dispatchEvent(event));
                }
                return results;
            }
        });

        $(document).on('click', '[data-datagrid-editable-url]', function(event) {
            var attr_name, attr_value, attrs, cell, cellValue, cell_height, cell_lines, cell_padding, input, line_height, submit, valueToEdit;
            cell = $(this);
            if (event.target.tagName.toLowerCase() === 'a') {
                return;
            }
            if (cell.hasClass('datagrid-inline-edit')) {
                return;
            }
            if (!cell.hasClass('editing')) {
                cell.addClass('editing');
                cellValue = cell.html().trim().replace('<br>', '\n');
                if (cell.attr('data-datagrid-editable-value')) {
                    valueToEdit = cell.data('datagrid-editable-value');
                } else {
                    valueToEdit = cellValue;
                }
                cell.data('originalValue', cellValue);
                cell.data('valueToEdit', valueToEdit);
                if (cell.data('datagrid-editable-type') === 'textarea') {
                    input = $('<textarea>' + valueToEdit + '</textarea>');
                    cell_padding = parseInt(cell.css('padding').replace(/[^-\d\.]/g, ''), 10);
                    cell_height = cell.outerHeight();
                    line_height = Math.round(parseFloat(cell.css('line-height')));
                    cell_lines = (cell_height - (2 * cell_padding)) / line_height;
                    input.attr('rows', Math.round(cell_lines));
                } else if (cell.data('datagrid-editable-type') === 'select') {
                    input = $(cell.data('datagrid-editable-element'));
                    input.find('option').each(function() {
                        if ($(this).text() === valueToEdit) {
                            return input.find("option[value='" + valueToEdit + "']").prop('selected', true);
                        }
                    });
                } else {
                    input = $('<input type="' + cell.data('datagrid-editable-type') + '">');
                    input.val(valueToEdit);
                }
                attrs = cell.data('datagrid-editable-attrs');
                for (attr_name in attrs) {
                    attr_value = attrs[attr_name];
                    input.attr(attr_name, attr_value);
                }
                cell.removeClass('edited');
                cell.html(input);
                submit = function(cell, el) {
                    var value;
                    value = el.val();
                    if (value !== cell.data('valueToEdit')) {
                        naja.makeRequest(
                            'POST',
                            cell.data('datagrid-editable-url'),
                            {value: value}
                        ).then( payload => {
                            if (cell.data('datagrid-editable-type') === 'select') {
                                cell.html(input.find("option[value='" + value + "']").html());
                            } else {
                                if (payload._datagrid_editable_new_value) {
                                    value = payload._datagrid_editable_new_value;
                                }
                                cell.html(value);
                            }
                            return cell.addClass('edited');
                        }).catch(() => {
                            cell.html(cell.data('originalValue'));
                            return cell.addClass('edited-error');
                        });
                    } else {
                        cell.html(cell.data('originalValue'));
                    }
                    return setTimeout(function() {
                        return cell.removeClass('editing');
                    }, 1200);
                };
                cell.find('input,textarea,select').focus().on('blur', function() {
                    return submit(cell, $(this));
                }).on('keydown', function(e) {
                    if (cell.data('datagrid-editable-type') !== 'textarea') {
                        if (e.which === 13) {
                            e.stopPropagation();
                            e.preventDefault();
                            return submit(cell, $(this));
                        }
                    }
                    if (e.which === 27) {
                        e.stopPropagation();
                        e.preventDefault();
                        cell.removeClass('editing');
                        return cell.html(cell.data('originalValue'));
                    }
                });
                return cell.find('select').on('change', function() {
                    return submit(cell, $(this));
                });
            }
        });

        $(document).on('click', '[data-datagrid-toggle-inline-add]', function(e) {
            var row;
            e.stopPropagation();
            e.preventDefault();
            row = $(this).closest('.datagrid').find('.datagrid-row-inline-add');
            if (row.hasClass('datagrid-row-inline-add-hidden')) {
                row.removeClass('datagrid-row-inline-add-hidden');
            }
            return row.find('input:not([readonly]),textarea:not([readonly])').first().focus();
        });

        $(document).on('mouseup', '[data-datagrid-cancel-inline-add]', function(e) {
            var code;
            code = e.which || e.keyCode || 0;
            if (code === 1) {
                e.stopPropagation();
                e.preventDefault();
                return $('.datagrid-row-inline-add').addClass('datagrid-row-inline-add-hidden');
            }
        });

        this.datagridSortable();
        this.datagridSortableTree();
        this.datagridFilterMultiSelect();
        this.datagridGroupActionMultiSelect();
        this.datagridFilterDateRange();

    }

    private onBefore($el: JQuery) {
        // datagrid.confirm
        (function () {
            const confirmMessage = $el.data('datagrid-confirm');
            if (confirmMessage) {
                return confirm(confirmMessage);
            }
        })();

        // datargid.item_detail
        (function () {
            const id = $el.attr('data-toggle-detail');
            let row_detail;
            if (id) {
                row_detail = $('.item-detail-' + id);
                if (row_detail.hasClass('loaded')) {
                    if (!row_detail.find('.item-detail-content').length) {
                        row_detail.removeClass('toggled');
                        return true;
                    }
                    if (row_detail.hasClass('toggled')) {
                        row_detail.find('.item-detail-content').slideToggle('fast', (function(_this) { // wtf is _this, this is even not used!
                            return function() {
                                return row_detail.toggleClass('toggled');
                            };
                        })(this));
                    } else {
                        row_detail.toggleClass('toggled');
                        row_detail.find('.item-detail-content').slideToggle('fast');
                    }
                    return false;
                } else {
                    return row_detail.addClass('loaded');
                }
            }
        })();

        // datagrid.tree
        (function (){
            var children_block;
            if ($el.attr('data-toggle-tree')) {
                $el.toggleClass('toggle-rotate');
                children_block = $el.closest('.datagrid-tree-item').find('.datagrid-tree-item-children').first();
                if (children_block.hasClass('loaded')) {
                    children_block.slideToggle('fast');
                    return false;
                }
            }
            return true;
        })();
    }

    private onSuccess(payload) {
        // datagrid.happy
        (function () {
            let c, checked_rows, class_selector, classes, event, grid, grids, i, ie, input, j, len, len1, results;

            if (DatagridExtension.happy) {
                DatagridExtension.happy.reset();
            }
            grids = $('.datagrid');
            results = [];
            for (i = 0, len = grids.length; i < len; i++) {
                grid = grids[i];
                classes = grid.classList;
                class_selector = '';
                for (j = 0, len1 = classes.length; j < len1; j++) {
                    c = classes[j];
                    class_selector = class_selector + '.' + c;
                }
                checked_rows = document.querySelectorAll(class_selector + ' ' + 'input[data-check]:checked');
                if (checked_rows.length === 1 && checked_rows[0].getAttribute('name') === 'toggle-all') {
                    input = document.querySelector(class_selector + ' input[name=toggle-all]');
                    if (input) {
                        input.checked = false;
                        ie = window.navigator.userAgent.indexOf("MSIE ");
                        if (ie) {
                            event = document.createEvent('Event');
                            event.initEvent('change', true, true);
                        } else {
                            event = new Event('change', {
                                'bubbles': true
                            });
                        }
                        results.push(input.dispatchEvent(event));
                    } else {
                        results.push(void 0);
                    }
                } else {
                    results.push(void 0);
                }
            }
        })();

        // datagrid.sortable
        this.datagridSortable();

        // datagrid.forms
        $('.datagrid').find('form').each(function () {
            return window.Nette.initForm(this);
        });

        // datagrid.url
        (function () {
            let host, path, query, url;

            if (payload._datagrid_url) {
                if (window.history.pushState) {
                    host = window.location.protocol + "//" + window.location.host;
                    path = window.location.pathname;
                    query = DatagridExtension.datagridSerializeUrl(payload.state).replace(/&+$/gm, '');
                    if (query) {
                        url = host + path + "?" + query.replace(/\&*$/, '');
                    } else {
                        url = host + path;
                    }
                    url += window.location.hash;
                    if (window.location.href !== url) {
                        return window.history.pushState({
                            path: url
                        }, '', url);
                    }
                }
            }
        })();

        // datagrid.sort
        (function () {
            var href, key, ref, results;
            if (payload._datagrid_sort) {
                ref = payload._datagrid_sort;
                results = [];
                for (key in ref) {
                    href = ref[key];
                    results.push($('#datagrid-sort-' + key).attr('href', href));
                }
                return results;
            }
        })();

        // datargid.item_detail
        (function () {
            var id, row_detail;
            if (payload._datagrid_toggle_detail) {
                id = payload._datagrid_toggle_detail;
                row_detail = $('.item-detail-' + id);
                row_detail.toggleClass('toggled');
                return row_detail.find('.item-detail-content').slideToggle('fast');
            }
        })();

        // datagrid.tree
        (() => {
            var children_block, content, id, name, ref, snippet, template;
            if (payload._datagrid_tree) {
                id = payload._datagrid_tree;
                children_block = $('.datagrid-tree-item[data-id="' + id + '"]').find('.datagrid-tree-item-children').first();
                children_block.addClass('loaded');
                ref = payload.snippets;
                for (name in ref) {
                    snippet = ref[name];
                    content = $(snippet);
                    template = $('<div class="datagrid-tree-item" id="' + name + '">');
                    template.attr('data-id', content.attr('data-id'));
                    template.append(content);
                    if (content.data('has-children')) {
                        template.addClass('has-children');
                    }
                    children_block.append(template);
                }
                children_block.addClass('loaded');
                children_block.slideToggle('fast');
                // this.naja.load(); // todo Proč? A jde to vůbec z naja zavolat?
            }
            return this.datagridSortableTree();
        })();

        // datagrid-toggle-inline-add
        (() => {
            if (payload._datagrid_inline_added) {
                $('.datagrid-row-inline-add').find('textarea').html('');
                $('.datagrid-row-inline-add').find('input[type!=submit]').val('');
                return $('.datagrid-row-inline-add').addClass('datagrid-row-inline-add-hidden');
            }
        })();

        // TODO: bootstrap - checknout, zde někde nepoužíváme ještě fontawesome -> pak smazat :-)
        // datagrid.filterMultiSelect
        (() => {
            this.datagridFilterMultiSelect();
            if ($.fn.selectpicker) {
                return $('.selectpicker').selectpicker({
                    iconBase: 'bi',
                    tickIcon: 'bi bi-check',
                });
            }
        })();

        // datagrid.groupActionMultiSelect
        (() => this.datagridGroupActionMultiSelect())();

        // datagrid.inline-editing
        (() => {
            if (payload._datagrid_inline_editing) {
                const grid = $('.datagrid-' + payload._datagrid_name);
                return grid.find('.datagrid-inline-edit-trigger').addClass('hidden');
            }
        })();

        // datagrid.redraw-item
        (() => {
            if (payload._datagrid_redraw_item_class) {
                const row = $('tr[data-id=' + payload._datagrid_redraw_item_id + ']');
                return row.attr('class', payload._datagrid_redraw_item_class);
            }
        })();

        // datagrid.reset-filter-by-column
        (() => {
            let grid, href, i, key, len, ref;
            if (!payload._datagrid_name) {
                return;
            }
            grid = $('.datagrid-' + payload._datagrid_name);
            grid.find('[data-datagrid-reset-filter-by-column]').addClass('hidden');
            if (payload.non_empty_filters && payload.non_empty_filters.length) {
                ref = payload.non_empty_filters;
                for (i = 0, len = ref.length; i < len; i++) {
                    key = ref[i];
                    grid.find('[data-datagrid-reset-filter-by-column=' + key + ']').removeClass('hidden');
                }
                href = grid.find('.reset-filter').attr('href');
                return grid.find('[data-datagrid-reset-filter-by-column]').each(function() {
                    var new_href;
                    key = $(this).attr('data-datagrid-reset-filter-by-column');
                    new_href = href.replace('do=' + payload._datagrid_name + '-resetFilter', 'do=' + payload._datagrid_name + '-resetColumnFilter');
                    new_href += '&' + payload._datagrid_name + '-key=' + key;
                    return $(this).attr('href', new_href);
                });
            }
        })();
    }

    private getEventDomPath(e) {
        if (e.path?.length) {
             return e.path;
        }

        const path = [];
        let node = e.target;
        while (node !== document.body) {
            if (node === null) {
                break;
            }
            path.push(node);
            node = node.parentNode;
        }
        return path;
    }

    private datagridShiftGroupSelection() {
        let last_checkbox = null;
        return document.addEventListener('click', (e) => {
            var checkboxes_rows, current_checkbox_row, el, event, i, ie, input, j, k, last_checkbox_row, last_checkbox_tbody, len, len1, len2, ref, ref1, results, row, rows;
            ref = this.getEventDomPath(e);
            for (i = 0, len = ref.length; i < len; i++) {
                el = ref[i];
                if ($(el).is('.col-checkbox') && last_checkbox && e.shiftKey) {
                    current_checkbox_row = $(el).closest('tr');
                    last_checkbox_row = last_checkbox.closest('tr');
                    last_checkbox_tbody = last_checkbox_row.closest('tbody');
                    checkboxes_rows = last_checkbox_tbody.find('tr').toArray();
                    if (current_checkbox_row.index() > last_checkbox_row.index()) {
                        rows = checkboxes_rows.slice(last_checkbox_row.index(), current_checkbox_row.index());
                    } else if (current_checkbox_row.index() < last_checkbox_row.index()) {
                        rows = checkboxes_rows.slice(current_checkbox_row.index() + 1, last_checkbox_row.index());
                    }
                    if (!rows) {
                        return;
                    }
                    for (j = 0, len1 = rows.length; j < len1; j++) {
                        row = rows[j];
                        input = $(row).find('.col-checkbox input[type=checkbox]')[0];
                        if (input) {
                            input.checked = true;
                            ie = window.navigator.userAgent.indexOf("MSIE ");
                            if (ie) {
                                event = document.createEvent('Event');
                                event.initEvent('change', true, true);
                            } else {
                                event = new Event('change', {
                                    'bubbles': true
                                });
                            }
                            input.dispatchEvent(event);
                        }
                    }
                }
            }
            ref1 = this.getEventDomPath(e);
            results = [];
            for (k = 0, len2 = ref1.length; k < len2; k++) {
                el = ref1[k];
                if ($(el).is('.col-checkbox')) {
                    results.push(last_checkbox = $(el));
                } else {
                    results.push(void 0);
                }
            }
            return results;
        });
    }

    private datagridSortable() {
        if (typeof $.fn.sortable === 'undefined') {
            return;
        }
        const naja = this.naja;
        return $('.datagrid [data-sortable]').sortable({
            handle: '.handle-sort',
            items: 'tr',
            axis: 'y',
            update: function(event, ui) {
                var component_prefix, data, item_id, next_id, prev_id, row, url;
                row = ui.item.closest('tr[data-id]');
                item_id = row.data('id');
                prev_id = null;
                next_id = null;
                if (row.prev().length) {
                    prev_id = row.prev().data('id');
                }
                if (row.next().length) {
                    next_id = row.next().data('id');
                }
                url = $(this).data('sortable-url');
                data = {};
                component_prefix = row.closest('.datagrid').find('tbody').attr('data-sortable-parent-path');
                data[(component_prefix + '-item_id').replace(/^-/, '')] = item_id;
                if (prev_id !== null) {
                    data[(component_prefix + '-prev_id').replace(/^-/, '')] = prev_id;
                }
                if (next_id !== null) {
                    data[(component_prefix + '-next_id').replace(/^-/, '')] = next_id;
                }
                return naja.makeRequest('GET', url, data)
                    .catch((error) =>  {
                        return alert(error.message);
                    });
            },
            helper: function(e, ui) {
                ui.children().each(function() {
                    return $(this).width($(this).width());
                });
                return ui;
            }
        });

    }

    private datagridSortableTree() {
        if (typeof $('.datagrid-tree-item-children').sortable === 'undefined') {
            return;
        }
        const naja = this.naja;
        return $('.datagrid-tree-item-children').sortable({
            handle: '.handle-sort',
            items: '.datagrid-tree-item:not(.datagrid-tree-header)',
            toleranceElement: '> .datagrid-tree-item-content',
            connectWith: '.datagrid-tree-item-children',
            update: function(event, ui) {
                var component_prefix, data, item_id, next_id, parent, parent_id, prev_id, row, url;
                $('.toggle-tree-to-delete').remove();
                row = ui.item.closest('.datagrid-tree-item[data-id]');
                item_id = row.data('id');
                prev_id = null;
                next_id = null;
                parent_id = null;
                if (row.prev().length) {
                    prev_id = row.prev().data('id');
                }
                if (row.next().length) {
                    next_id = row.next().data('id');
                }
                parent = row.parent().closest('.datagrid-tree-item');
                if (parent.length) {
                    parent.find('.datagrid-tree-item-children').first().css({
                        display: 'block'
                    });
                    parent.addClass('has-children');
                    parent_id = parent.data('id');
                }
                url = $(this).data('sortable-url');
                if (!url) {
                    return;
                }
                parent.find('[data-toggle-tree]').first().removeClass('hidden');
                component_prefix = row.closest('.datagrid-tree').attr('data-sortable-parent-path');
                data = {};
                data[(component_prefix + '-item_id').replace(/^-/, '')] = item_id;
                if (prev_id !== null) {
                    data[(component_prefix + '-prev_id').replace(/^-/, '')] = prev_id;
                }
                if (next_id !== null) {
                    data[(component_prefix + '-next_id').replace(/^-/, '')] = next_id;
                }
                data[(component_prefix + '-parent_id').replace(/^-/, '')] = parent_id;
                return naja.makeRequest('GET', url, data)
                    .catch((error) => {
                        return alert(error.message);
                    });
            },
            stop: function(event, ui) {
                return $('.toggle-tree-to-delete').removeClass('toggle-tree-to-delete');
            },
            start: function(event, ui) {
                var parent;
                parent = ui.item.parent().closest('.datagrid-tree-item');
                if (parent.length) {
                    if (parent.find('.datagrid-tree-item').length === 2) {
                        return parent.find('[data-toggle-tree]').addClass('toggle-tree-to-delete');
                    }
                }
            }
        });
    }

    private datagridFilterMultiSelect() {
        const select = $('.selectpicker').first();
        if ($.fn.selectpicker) {
            return $.fn.selectpicker.defaults = {
                countSelectedText: select.data('i18n-selected'),
                iconBase: 'bi',
                tickIcon: select.data('selected-icon-check') ?? 'bi-check',
            };
        }
    }

    private datagridGroupActionMultiSelect() {
        if (!$.fn.selectpicker) {
            return;
        }
        const selects = $('[data-datagrid-multiselect-id]');
        return selects.each(function() {
            let id;col-pagination
            if ($(this).hasClass('selectpicker')) {
                $(this).removeAttr('id');
                id = $(this).data('datagrid-multiselect-id');
                $(this).on('loaded.bs.select', function(e) {
                    $(this).parent().attr('style', 'display:none;');
                    return $(this).parent().find('.hidden').removeClass('hidden').addClass('btn-default btn-secondary');
                });
                $(this).on('rendered.bs.select', function(e) {
                    return $(this).parent().attr('id', id);
                });
            }
        });
    }

    private datagridFilterDateRange() {
        $('.datagrid').on('change', 'input.datepicker', function(e) {
            const $this = $(this);
            const selectedDate = this._flatpickr.selectedDates[0] || null;

            if ($this.data('date-range-to')) {
                const $to = $('#' + $this.data('date-range-to'));
                const toDate = $to[0]._flatpickr.selectedDates[0] || null;
                if (selectedDate && toDate && selectedDate > toDate) {
                    $to[0]._flatpickr.setDate(selectedDate);
                }
            }

            if ($this.data('date-range-from')) {
                const $from = $('#' + $this.data('date-range-from'));
                const fromDate = $from[0]._flatpickr.selectedDates[0] || null;
                if (selectedDate && fromDate && fromDate > selectedDate) {
                    $from[0]._flatpickr.setDate(selectedDate);
                }
            }
        });
    }

}
