import ImageReposition from "./ImageReposition";
import './leaflet/leaflet.rrose.css';
import './leaflet/leaflet.rrose-src.js';
import BaseComponent from "../../BaseComponent";

declare global {
    interface JQuery {
        dialog: (...args: any[]) => JQuery<HTMLElement>,
        imgRating: (...args: any[]) => JQuery<HTMLElement>,
    }
}

export default class ImageRating extends BaseComponent {
    private $popup: JQuery<HTMLElement>;
    private selectedMarker = null;
    private isPopupOpen = false;
    private $image: JQuery<HTMLImageElement>;
    private $imageContainer: JQuery;
    private imageOptions: {[property: string]: any};

    public init(): void {
        this.$popup = this.$component.find('[data-image-note-dialog]').dialog({
            dialogClass: "no-close",
            autoOpen: false
        });

        (new ImageReposition()).initialize(this.context.naja);

        this.$image = <JQuery<HTMLImageElement>>this.$component.find('[data-component-image-rating]');
        this.$imageContainer = this.$component.find('[data-image-container]');
        this.selectedMarker = null;
        this.isPopupOpen = false;
        this.imageOptions = this.$image.data('options');

        const self = this;


        this.$image.imgRating({
            icon: {
                iconShape: 'doughnut',
                borderWidth: 5,
                borderColor: '#337ab7',
                backgroundColor: '#fff'
            },
            beforeIconCreated: (iconOptions, data) => {
                if (data.rating > 0) {
                    iconOptions.borderColor = '#69AA46';
                } else if (data.rating < 0) {
                    iconOptions.borderColor = '#d74543';
                }
            },
            onMarkerAdded: (marker, data) => {
                marker.ratingId = data.id;

                if (this.imageOptions.isEditable) {
                    if (data.id === undefined) {
                        this.openPopup(marker, data);
                    }

                    marker.on('click', () =>  {
                        this.openPopup(marker, data);
                    });
                }
            },
            onClick: function(e, pos) {
                if (!self.imageOptions.isEditable) {
                    alert(self.$image.data('deadline-message'));
                    return;
                }

                // Don't bother responding to the click if the dialog window is open.
                if (self.isPopupOpen === true) {
                    return;
                }

                // Disallow placing markers near the top edge, which makes them really hard to click afterwards.
                if (pos.y > 0.01) {
                    this.addMarker({x: pos.x.toFixed(3), y: pos.y.toFixed(3)});
                }
            },
            afterInit: function() {
                $('.leaflet-grab').css('cursor', 'crosshair');
                this.import(self.imageOptions.ratings);
            },
            viewDestination: this.$imageContainer[0],
        });

        $('button[name="close"]', this.$popup).on('click', (e) => {
            e.preventDefault();

            if (this.selectedMarker.ratingId === undefined) {
                this.selectedMarker.remove();
                this.selectedMarker = null;
            }

            self.closePopup();
        });

        $('button[name="save"]', this.$popup).on('click', (e) => {
            e.preventDefault();

            const $form = <JQuery<HTMLFormElement>>$(e.currentTarget).closest('form');

            // Check comment when needed
            if (!window.Nette.validateForm($form[0], false)) {
                return false;
            }

            this.context.naja.uiHandler.submitForm($form[0]).then(payload => this.redrawRatings(payload.imageRatings));

        });

        $('button[name="remove"]', this.$popup).on('click', (e) => {
            e.preventDefault();

            const $form = <JQuery<HTMLFormElement>>$(e.currentTarget).closest('form');

            if (this.selectedMarker.ratingId === undefined) {
                this.selectedMarker.remove();
                this.selectedMarker = null;
                this.closePopup();
            } else {
                this.context.naja.uiHandler.submitForm($form[0]).then(payload => this.redrawRatings(payload.imageRatings));
            }
        });
	}


	private setFormValues(data): void {
		$('[name="rating"]', this.$popup)
			.filter('[value=' + (data.rating !== undefined ? data.rating : 0) + ']')
			.prop('checked', true);

		$('[name="note"]', this.$popup).val(data.note).change();
		$('[name="id"]', this.$popup).val(data.id !== undefined ? data.id : '');
		$('[name="x"]', this.$popup).val(data.x);
		$('[name="y"]', this.$popup).val(data.y);
	}

	private openPopup(marker, data): void {
		this.selectedMarker = marker;
		this.setFormValues(data);
		this.$popup.dialog('open');
		this.isPopupOpen = true;
	}

	private closePopup(): void {
		this.$popup.dialog('close');
        this.isPopupOpen = false;
	}

	private redrawRatings(ratings): void {
		this.imageOptions.ratings = ratings;
		this.$image.imgRating('clear');
		this.$image.imgRating('import', ratings);
		this.closePopup();
	}
}
