/**
 * String to lower, no diacritics, trim, replace multiple spaces with one space
 * @example normalizeString(' Příliž žluťoučký   kůň trpěl.') => 'priliz zloutoucky kun trpel.'
 * @param str
 */
export default function normalizeString(str: string) {
    if (str.normalize) {
        str = str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    }
    return str.replace(/ +/, ' ').trim().toLocaleLowerCase();
}