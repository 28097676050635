//--------------------------------
// IMPORT STYLE and IMAGES
import './styles/index.scss';
const imagesContext = require.context('./images', true, /\.(png|jpg|jpeg|gif|ico|svg|webp)$/);
imagesContext.keys().forEach(imagesContext);

const themeImagesContext = require.context('./images', true, /\.(png|jpg|jpeg|gif|ico|svg|webp)$/);
themeImagesContext.keys().forEach(themeImagesContext);

declare global { // declare pages to window
    interface Window {
        sentry: string,
        $: JQueryStatic,
        ckeditors?: {[id: string]: ClassicEditor},
    }
}

window.$ = $;

//--------------------------------
// IMPORT GLOBAL FILES
import "bootstrap";
import naja from 'naja';
import { ComponentFactory, ComponentsType } from './components/ComponentFactory';
import ExtensionRegistrar from './extensions/ExtensionRegistrar';

//--------------------------------
// IMPORT LIBRARIES
import 'webpack-jquery-ui';
import 'webpack-jquery-ui/css';
import 'hammerjs';
import 'jquery-hammerjs';
import 'jquery-mousewheel';
import 'what-input';

import './lib/jquery.nms.extensions';
import './lib/imgViewer2'; // jquery plugin


//--------------------------------
// CONFIGURE SENTRY
import * as Sentry from '@sentry/browser';

if (process.env.NODE_ENV === 'production') {
    Sentry.init({
        dsn: 'https://0765b5cb279c47f187b7e28af8ffbb0b@o1168082.ingest.sentry.io/6265793',
        integrations: [new Sentry.BrowserTracing()],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 0.0,

    });
}

//--------------------------------
// IMPORT & REGISTER COMPONENTS
import ActivationStep from "./components/ActivationStep";
import Autocomplete from "./components/Autocomplete";
import CheckAll from "./components/CheckAll";
import DisableElements from "./components/DisableElements";
import ClientSelection from "./components/ClientSection";
import CommentForm from "./components/CommentForm";
import DashboardWidgets from "./components/DashboardWidgets";
import Diary from "./components/Diary/js/Diary";
import EntryTags from "./components/EntryTags";
import FileInput from "./components/FileInput";
import FlashOverlayModal from "./components/FlashOverlayModal";
import GeneralInfoForm from "./components/GeneralInfoForm";
import GridActionOnChange from "./components/GridActionOnChange";
import ImageRating, {ImageRatingView} from "./components/ImageRating";
import EmailEditor from "./components/EmailEditor";
import FilterContainer from "./components/FilterContainer";
import PollForm from "./components/PollForm/js/PollForm";
import VideoCallForm from "./components/VideoCallForm/js/VideoCallForm";
import VideoCallTimer from "./components/VideoCallTimer/js/VideoCallTimer";
import PremiumBenefitsCard from "./components/PremiumBenefitsCard";
import PrivateMessages from "./components/PrivateMessages/js/PrivateMessages";
import { ProjectFormAttributes, ProjectFormQuestionary, ProjectFormTouchpoints } from "./components/ProjectForm";
import RespondentSelection from "./components/RespondentSelection";
import DiaryRewardsForm from "./components/DiaryRewardsForm";
import UsersImport from "./components/UsersImport";
import SelectProjectFilter from "./components/SelectProjectFilter/js/SelectProjectFilter";
import RegistrationForm from "./components/RegistrationForm";
import ToastMessage from "./components/ToastMessage";
import UploadAvatarForm from './components/UploadAvatarForm';
import UploadAreaForm from './components/UploadAreaForm';
import UploadCountInputLabel from "./components/UploadCountInputLabel";
import Recaptcha from './components/Recaptcha';
import LanguageSwitch from './components/LanguageSwitch';
import { OpenAIMinutes, OpenAIInsightFinder } from './components/OpenAI';
import SearchInput from './components/SearchInput';
import StarsRating from './components/StarsRating';
import BulkEmailForm from './components/BulkEmailForm';

const components: ComponentsType = {
    ActivationStep: ActivationStep,
    Autocomplete: Autocomplete,
    BulkEmailForm: BulkEmailForm,
    Chatbot: Chatbot,
    CheckAll: CheckAll,
    ClientSelection: ClientSelection,
    CommentForm: CommentForm,
    DashboardWidgets: DashboardWidgets,
    Diary: Diary,
    DiaryRewardsForm: DiaryRewardsForm,
    DisableElements: DisableElements,
    DiscussionFormConfirmation: DiscussionFormConfirmation,
    EmailEditor: EmailEditor,
    EntryTags: EntryTags,
    FileInput: FileInput,
    FiltersContainer: FilterContainer,
    FlashOverlayModal: FlashOverlayModal,
    GeneralInfoForm: GeneralInfoForm,
    GridActionOnChange: GridActionOnChange,
    ImageRating: ImageRating,
    ImageRatingView: ImageRatingView,
    LanguageSwitch: LanguageSwitch,
    OpenAIInsightFinder: OpenAIInsightFinder,
    OpenAIMinutes: OpenAIMinutes,
    OpenAITopicReport: OpenAITopicReport,
    PhoneInput: PhoneInput,
    PollForm: PollForm,
    PremiumBenefitsCard: PremiumBenefitsCard,
    PrivateMessages: PrivateMessages,
    ProjectFormAttributes: ProjectFormAttributes,
    ProjectFormQuestionary: ProjectFormQuestionary,
    ProjectFormTouchpoints: ProjectFormTouchpoints,
    Recaptcha: Recaptcha,
    RegistrationForm: RegistrationForm,
    RespondentSelection: RespondentSelection,
    SearchInput: SearchInput,
    SelectContext: SelectContext,
    SelectProjectFilter: SelectProjectFilter,
    StarsRating: StarsRating,
    ToastMessage: ToastMessage,
    UploadAreaForm: UploadAreaForm,
    UploadAvatarForm: UploadAvatarForm,
    UploadCountInputLabel: UploadCountInputLabel,
    UsersImport: UsersImport,
    VideoCallForm: VideoCallForm,
    VideoCallTimer: VideoCallTimer,
}


//--------------------------------
// IMPORT & REGISTER EXTENSIONS
ExtensionRegistrar.addExtension(new ComponentFactory(components));

import NetteFormsExtension from "./extensions/NetteFormsExtension";
ExtensionRegistrar.addExtension(new NetteFormsExtension());

import {DatagridExtension, DatagridConfirm, DatagridSpinnersExtension, DatagridInstantUrlRefresh } from './extensions/Datagrid';
ExtensionRegistrar.addExtension(new DatagridExtension());
ExtensionRegistrar.addExtension(new DatagridConfirm());
ExtensionRegistrar.addExtension(new DatagridSpinnersExtension());
ExtensionRegistrar.addExtension(new DatagridInstantUrlRefresh());

import RevealMainContentExtension from "./extensions/RevealMainContentExtension";
ExtensionRegistrar.addExtension(new RevealMainContentExtension());


import ActionAfterChangeExtension from "./extensions/ActionAfterChangeExtension";
ExtensionRegistrar.addExtension(new ActionAfterChangeExtension());

import ClipboardExtension from "./extensions/ClipboardExtension";
ExtensionRegistrar.addExtension(new ClipboardExtension());

import CloseModalsExtension from "./extensions/CloseModalsExtension";
ExtensionRegistrar.addExtension(new CloseModalsExtension());

import OpenModalsExtension from "./extensions/OpenModalsExtension";
ExtensionRegistrar.addExtension(new OpenModalsExtension());

import ConfirmExtension from "./extensions/ConfirmExtension";
ExtensionRegistrar.addExtension(new ConfirmExtension());

import DependSelectBoxExtension from "./extensions/DependentSelectBox/js/DependSelectBoxExtension";
ExtensionRegistrar.addExtension(new DependSelectBoxExtension());

import FileUploadExtension from "./extensions/FileUploadExtension";
ExtensionRegistrar.addExtension(new FileUploadExtension());

import HideOnEmptyContentExtension from "./extensions/HideOnEmptyContentExtension";
ExtensionRegistrar.addExtension(new HideOnEmptyContentExtension());

import InactiveLinksExtension from "./extensions/InactiveLinksExtension";
ExtensionRegistrar.addExtension(new InactiveLinksExtension());

import IosFileUploadFixExtension from "./extensions/IosFileUploadFixExtension";
ExtensionRegistrar.addExtension(new IosFileUploadFixExtension());

import LightboxExtension from "./extensions/Lightbox";
ExtensionRegistrar.addExtension(new LightboxExtension());

import ModalExtension from "./extensions/ModalExtension";
ExtensionRegistrar.addExtension(new ModalExtension());

import OnLoadAjaxExtension from "./extensions/OnLoadAjaxExtension";
ExtensionRegistrar.addExtension(new OnLoadAjaxExtension());

import OnClickRedirectExtension from "./extensions/OnClickRedirectExtension";
ExtensionRegistrar.addExtension(new OnClickRedirectExtension());

import PopoversExtension from "./extensions/PopoversExtension";
ExtensionRegistrar.addExtension(new PopoversExtension());

import PreventEnterSubmitExtension from "./extensions/PreventEnterSubmitExtension";
ExtensionRegistrar.addExtension(new PreventEnterSubmitExtension());

import ProcessHiddenFormsExtension from "./extensions/ProcessHiddenFormsExtension";
ExtensionRegistrar.addExtension(new ProcessHiddenFormsExtension());

import RevealDiaryExtension from "./extensions/RevealDiaryExtension";
ExtensionRegistrar.addExtension(new RevealDiaryExtension());

import RevealDiscussionExtension from "./extensions/RevealDiscussionExtension";
ExtensionRegistrar.addExtension(new RevealDiscussionExtension());

import RevealLinkExtension from "./extensions/RevealLinkExtension";
ExtensionRegistrar.addExtension(new RevealLinkExtension());

import SelectizeExtension from "./extensions/SelectizeExtension";
ExtensionRegistrar.addExtension(new SelectizeExtension());

import ShowLoaderExtension from "./extensions/ShowLoaderExtension";
ExtensionRegistrar.addExtension(new ShowLoaderExtension());

import AjaxSpinnersExtension from "./extensions/AjaxSpinnersExtension";
ExtensionRegistrar.addExtension(new AjaxSpinnersExtension());

import SubmitFormOnEnterKeyExtension from "./extensions/SubmitFormOnEnterKeyExtension";
ExtensionRegistrar.addExtension(new SubmitFormOnEnterKeyExtension());

import SubmittedFormsExtension from "./extensions/SubmittedFormsExtension";
ExtensionRegistrar.addExtension(new SubmittedFormsExtension());

import TabsExtension from "./extensions/TabsExtension";
ExtensionRegistrar.addExtension(new TabsExtension());

import TooltipExtension from "./extensions/TooltipExtension";
ExtensionRegistrar.addExtension(new TooltipExtension());

import ScrollToElementExtension from "./extensions/ScrollToElementExtension";
ExtensionRegistrar.addExtension(new ScrollToElementExtension());

import ScrollToAnchorExtension from "./extensions/ScrollToAnchorExtension";
ExtensionRegistrar.addExtension(new ScrollToAnchorExtension());

import UserActivitiesExtension from "./extensions/UserActivitiesExtension";
ExtensionRegistrar.addExtension(new UserActivitiesExtension());

import WarnOnUnsavedChangesExtension from "./extensions/WarnOnUnsavedChangesExtension";
ExtensionRegistrar.addExtension(new WarnOnUnsavedChangesExtension());

import YoutubeVideosExtension from "./extensions/YoutubeVideosExtension";
ExtensionRegistrar.addExtension(new YoutubeVideosExtension());

import { DatePicker, DateTimePicker } from "./extensions/FlatPickr";
ExtensionRegistrar.addExtension(new DatePicker());
ExtensionRegistrar.addExtension(new DateTimePicker());

import Dashboard from "./extensions/Dashboard";
ExtensionRegistrar.addExtension(new Dashboard());

import InfiniteScrollExtension from "./extensions/InfiniteScrollExtension";
ExtensionRegistrar.addExtension(new InfiniteScrollExtension());

import UserFreeAccountChecker from './extensions/UserFreeAccountChecker';
ExtensionRegistrar.addExtension(new UserFreeAccountChecker());

import ClickTargetExtension from './extensions/ClickTargetExtension';
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
ExtensionRegistrar.addExtension(new ClickTargetExtension());

import SnippetIntervalRefreshExtension from "./extensions/SnippetIntervalRefreshExtension";
ExtensionRegistrar.addExtension(new SnippetIntervalRefreshExtension());

import ExcelExportExtension from './extensions/Excel';
import PhoneInput from "./components/PhoneInput";
import DiscussionFormConfirmation from "./components/DiscussionFormConfirmation";
import OpenAITopicReport from "./components/OpenAI/js/OpenAITopicReport";
ExtensionRegistrar.addExtension(new ExcelExportExtension());

import PreventDoubleSubmitExtension from "./extensions/PreventDoubleSubmitExtension";
import Chatbot from "./components/Chatbot";
import SelectContext from "./components/SelectContext";
ExtensionRegistrar.addExtension(new PreventDoubleSubmitExtension());

import DateRangeExtension from "./extensions/DateRangeExtension";
ExtensionRegistrar.addExtension(new DateRangeExtension());


ExtensionRegistrar.register();

//--------------------------------
// INIT PAGE FUNCTION

document.addEventListener('DOMContentLoaded', () => {
    naja.initialize({
        history: false,
        historyUiCache: false
    });
});
