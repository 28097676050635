import ClassicExtension from "./ClassicExtension";

export default class DateRangeExtension extends ClassicExtension {

    protected init($root: JQuery): void {
        const $dates = $root.find('[data-date-range]');

        $dates.on('change', 'input.datepicker', function(e) {
            const $this = $(this);
            const selectedDate = this._flatpickr.selectedDates[0] || null;

            if ($this.data('date-range-to')) {
                const $to = $(`[name=${$this.data('date-range-to')}]`);
                const toDate = $to[0]._flatpickr.selectedDates[0] || null;
                if (selectedDate && toDate && selectedDate > toDate) {
                    $to[0]._flatpickr.setDate(selectedDate);
                }
            }

            if ($this.data('date-range-from')) {
                const $from = $(`[name=${$this.data('date-range-from')}]`);
                const fromDate = $from[0]._flatpickr.selectedDates[0] || null;
                if (selectedDate && fromDate && fromDate > selectedDate) {
                    $from[0]._flatpickr.setDate(selectedDate);
                }
            }
        });
    }
}
