import BaseComponent from "../../BaseComponent";
import { Dropdown } from "bootstrap";
import normalizeString from "../../../helpers/normalizeString";

export default class SelectContext extends BaseComponent {

    private maxSelectedItemsCount: number;

    init(): void {
        this.initSelectFunctions();
        const $questionTextarea = <JQuery<HTMLTextAreaElement>>this.$component.find('textarea[name="question"]');
        const $send = this.$component.find('button[name="send"]');
        const $predefinedQuestions = this.$component.find('div#predefined-questions');
        const $contextInput = this.$component.find('select#context-input');
        const $checkboxes = this.$component.find('ul input[type="checkbox"]');
        const $itemCheckboxes = this.$component.find('.item-checkbox');

        this.maxSelectedItemsCount = Number(this.$component.data('maxSelectedItemsCount') ?? 0);

        $checkboxes.on('change', () => {
            const itemCheckedCount = $itemCheckboxes.filter(':checked').length
            const tooManyItems = this.maxSelectedItemsCount && this.maxSelectedItemsCount < itemCheckedCount;

            $questionTextarea.prop('disabled', !itemCheckedCount);
            $send.prop('disabled', !itemCheckedCount);
            if (itemCheckedCount && !tooManyItems) {
                $predefinedQuestions.show('fast');
            } else {
                $predefinedQuestions.hide('fast')
            }

            // Get the values of all checked checkboxes
            const checkedValues = $checkboxes
                .filter(':checked')
                .map((_, checkbox) => $(checkbox).val())
                .get();

            const checkedValuesAsString = checkedValues.map(value => String(value));
            $contextInput.val(checkedValuesAsString);
        });

        // Trigger once to initialize the state
        $checkboxes.trigger('change');

        // Change height of textarea by its content
        $questionTextarea.on('input', () => {
            $questionTextarea[0].style.height = '5px';
            $questionTextarea[0].style.height = $questionTextarea[0].scrollHeight + 'px';

            $send.prop('disabled', !String($questionTextarea.val()).trim());
        });
        $questionTextarea.trigger('input');

        // Send question by hitting Enter, create new line with Shift+Enter
        $questionTextarea.on('keydown', e => {
            if (e.key === 'Enter' && !e.shiftKey && String($questionTextarea.val()).trim()) {
                e.preventDefault();

                $send.click();
                $questionTextarea.prop('disabled', true);
            }
        });

        // Disallow send form by send button when textarea is empty
        $send.closest('form').on('submit', e => {
            // @ts-ignore
            if ($(e.originalEvent.submitter).is('button[name="send"]') && !String($questionTextarea.val()).trim()) {
                e.preventDefault();
                $questionTextarea.focus();
            }
        })

    }


    initSelectFunctions() {
        const $groupCheckboxes = this.$component.find('.select-group');
        const $itemCheckboxes = this.$component.find('.item-checkbox');

        const $contextGroups = this.$component.find('.context-group');
        const $selectedItemsContainer = this.$component.find('#selected-items');

        const $searchInput = this.$component.find('#search-input');

        const dropdown = new Dropdown($searchInput[0]);

        $searchInput.prop('disabled', false);

        // Handle group checkbox changes
        $groupCheckboxes.each((_, groupCheckbox) => {
            $(groupCheckbox).on('change', (e) => {
                const $target = $(e.target);
                const group = $target.data('group');
                this.$component
                    .find(`.item-checkbox[data-group="${group}"]`)
                    .prop('checked', $target.prop('checked'));
                updateSelectedItems();
            });
        });

        // Uncheck group checkbox if any of its items are unchecked
        $itemCheckboxes.each((_, itemCheckbox) => {
            $(itemCheckbox).on('change', (e) => {
                const $target = $(e.target);
                const group = $target.data('group');
                const $groupCheckbox = this.$component.find(`.select-group[data-group="${group}"]`);
                const $groupItems = this.$component.find(`.item-checkbox[data-group="${group}"]`);
                const allChecked = $groupItems.toArray().every(item => $(item).prop('checked'));
                $groupCheckbox.prop('checked', allChecked);
                updateSelectedItems();
            });
        });

        // Filter items based on search input
        $searchInput.on('input', (e) => {

            const query = normalizeString($(e.target).val() ?? '');
            $contextGroups.each((_, group) => {
                const $group = $(group);
                const groupKeywords = ($group.data('keywords') || '');
                const $items = $group.find('.context-item');
                let groupHasVisibleItems = false;

                $items.each((_, item) => {
                    const $item = $(item);
                    const itemKeywords = $item.data('keywords');
                    const isVisible = itemKeywords.includes(query);

                    $item.css('display', isVisible ? 'block' : 'none');
                    if (isVisible) {
                        groupHasVisibleItems = true;
                    }
                });

                const $groupCheckbox = $group.find('.group-checkbox');
                const $groupInput = $groupCheckbox.find('input');
                const $groupLabel = $groupCheckbox.find('label');

                if ($groupCheckbox.length && $groupInput.length && $groupLabel.length) {
                    if (query) {
                        $groupCheckbox.hide();
                    } else {
                        $groupCheckbox.show();
                    }
                }

                $group.css('display', groupHasVisibleItems || !query ? 'block' : 'none');
            });
        });

        // Update the display of selected items
        const updateSelectedItems = () => {
            $selectedItemsContainer.empty(); // Clear the container

            let selectedCount = 0;
            $itemCheckboxes.each((_, checkbox) => {
                const $checkbox = $(checkbox);
                if ($checkbox.prop('checked')) {
                    selectedCount++;
                    const $button = $('<button>')
                        .addClass('btn btn-outline-secondary btn-sm')
                        .html(`${$checkbox.next('label').text().trim()} <i class="bi bi-x ms-2"></i>`)
                        .on('click', () => {
                            $checkbox.prop('checked', false);
                            updateSelectedItems();
                            $checkbox.trigger('change');
                        });

                    $selectedItemsContainer.append($button);

                }
            });

            if (this.maxSelectedItemsCount && selectedCount > this.maxSelectedItemsCount) {
                $selectedItemsContainer.prepend($(
                    '<div class="alert alert-warning w-100">'
                        + this.translator.translate('too_many_items').replace('%count%', ''+this.maxSelectedItemsCount)
                    + '</div>'
                ));
            }

            const placeholder = selectedCount
                ? this.translator.translate('selected').replace('{0}', ''+selectedCount)
                : this.translator.translate('select_context');
            $searchInput.attr('placeholder', placeholder);
        };

        // Add event listeners to item checkboxes
        $itemCheckboxes.each((_, checkbox) => {
            $(checkbox).on('change', updateSelectedItems);
        });

        // Initial population of selected items
        updateSelectedItems();

        this.$component.find('.dropdown-menu').each((_, element) => {
            $(element).on('click', (event) => {
                event.stopPropagation(); // Prevent the dropdown from closing when clicking the label or checkbox
            });
        });

        // Show the dropdown on input focus
        $searchInput.on("focus", () => {
            dropdown.show();
        });

        // Close dropdown if clicked outside
        $(document).on("click", (event) => {
            if (!$searchInput.is(event.target) && $searchInput.has(event.target).length === 0) {
                dropdown.hide();
            }
        });



    }

}