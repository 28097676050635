import IExtension from "./IExtension";
import {Naja} from "naja";

/**
 * Prevents double submit selected forms and every ajax form
 */
export default class PreventDoubleSubmitExtension implements IExtension {

    /**
     * Disabled elements waiting on request completed to be enabled
     */
    private readonly disabledElements: JQuery[] = [];

    initialize(naja: Naja) {
        naja.addEventListener('init', () => this.nonAjaxInteraction($('body')));
        naja.snippetHandler.addEventListener('afterUpdate', e => this.nonAjaxInteraction( $(<HTMLElement>e.detail.snippet) ));

        naja.uiHandler.addEventListener('interaction', e=> this.ajaxInteraction( $(<HTMLElement>e.detail.element) ));
        naja.addEventListener('complete', () => this.enableDisabledElements());
    }

    /**
     * For desired effect buttons (or other elements) must includes attribute [data-prevent-double-submit]
     * Disables element when form is submitted. Element will not be enabled - expecting full page reload.
     * Any other attempt to submit form will be prevented
     */
    private nonAjaxInteraction($root: JQuery): void {

        let disabledElements = 0;
        let prevent = false;

        $root.find('[data-prevent-double-submit]')
            .each((i, input) => {
                const $input = $(input);
                const $form = $input.closest('form');
                const form = <HTMLFormElement>$form[0];

                form?.addEventListener('submit', e => {
                    if (e.defaultPrevented === false) {
                        $input.addClass('disabled');
                        disabledElements++;
                    }
                    const count = $form.find('[data-prevent-double-submit]').length;
                    if (prevent || disabledElements > count) {
                        e.preventDefault();
                    }
                    if (disabledElements && disabledElements === count) {
                        prevent = true;
                    }
                });
            })
        ;
    }

    /**
     * Disable interacted input when it fires AJAX request
     * Input conditions:
     *  - has to submit form
     *  - must not show confirm message
     */
    private ajaxInteraction($interactedElement: JQuery): void {
        if ($interactedElement.is('input[type="submit"]:not([data-confirm])')) {
            const $form = $interactedElement.closest('form');
            if ($form.length > 0 && window.Nette.validateForm($form[0], true)) {
                $interactedElement.attr('disabled', 1);
                this.disabledElements.push($interactedElement);
            }
        }
    }

    private enableDisabledElements(): void {
        while (this.disabledElements.length > 0) {
            this.disabledElements.pop().prop('disabled', false);
        }
    }

}
